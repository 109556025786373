import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import {ReactComponent as Rails} from '../assets/skills-rails.svg';

import {ReactComponent as Adobe} from '../assets/skills-adobe.svg';
import {ReactComponent as Figma} from '../assets/skills-figma.svg';
import {ReactComponent as SASS} from '../assets/skills-sass.svg';
import {ReactComponent as ReactImg} from '../assets/skills-react.svg';
import {ReactComponent as CSS} from '../assets/skills-css.svg';
import {ReactComponent as Tailwind} from '../assets/skills-tailwind.svg';
import {ReactComponent as Firebase} from '../assets/skills-firebase.svg';
import {ReactComponent as Wordpress} from '../assets/skills-wordpress.svg';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

AOS.init();

const Freestyle = () => {
  return (
    <div name='freestyle' className='freestyle w-full'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='max-w-[1000px] w-full px-4 grid grid-cols-1 gap-8'>
          <div className='sm:text-left'>
            <h2 className='inline' data-aos="slide-up" data-aos-duration="300" data-aos-easing="ease-in-out">Recent projects</h2>
          </div>
        </div>

          {/* Dierks+Company section */}
          <div className='max-w-[1000px] w-full px-6 sm:px-1 grid sm:grid-cols-2 gap-1 md:gap-6 mb-[40px] sm:mb-[64px] justify-center items-center'>
          <VisibilitySensor partialVisibility={true} minTopValue={100}>
            {({ isVisible }) => (
            <div className="freedevice sm:order-2">
              <div className={isVisible ? "two-images zoom-in-left-big" : "two-images"} id={isVisible ?"dc-left" : ""}></div>
              <div className={isVisible ? "two-images zoom-in-right-small" : "two-images"}  id={isVisible ?"dc-right" : ""}></div>
            </div>
            )}
          </VisibilitySensor>
          <div className='textinfo'>
            <h4>Dierks+Company</h4>
            <p className="pb-4">For two years I worked as a Media/Design Consultant and Marketing Associate at Dierks+Company, a legal + strategy consultancy for healthcare and life sciences.</p>
            <div className="flex justify-center w-full gap-10 mt-4">
              <div className="items-center flex flex-col">
                <h5 className="mb-1">Tech-Stack</h5>
                <div className="flex gap-4 items-center">
                  <Figma className='w-8 h-8 dynamic-svg' alt="Figma icon"/>
                  <Adobe className='w-8 h-8 dynamic-svg' alt="Adobe icon"/>
                  <Wordpress className='w-8 h-8 dynamic-svg' alt="Wordpress icon"/>
                </div>
              </div>
              <div>
                <h5 className="text-center">Year</h5>
                <h6 className="mt-2">2023 -2024</h6>
              </div>
            </div>
          </div>
        </div>

        {/* History of Webdesign section */}
        <div className='max-w-[1000px] w-full px-6 sm:px-1 grid sm:grid-cols-2 gap-1 md:gap-6 mb-[40px] sm:mb-[64px] justify-center items-center'>
          <VisibilitySensor partialVisibility={true} minTopValue={100}>
            {({ isVisible }) => (
            <div className="freedevice">
              <div className={isVisible ? "two-images zoom-in-left-small" : "two-images"} id={isVisible ?"history-left" : ""}></div>
              <div className={isVisible ? "two-images zoom-in-right-big" : "two-images"}  id={isVisible ?"history-right" : ""}></div>
            </div>
            )}
          </VisibilitySensor>
          <div className='textinfo'>
            <h4>History of Webdesign...</h4>
            <p className="pb-4">...and me. Wonder, what a portfolio website would have looked like, since the beginning of <em>"The internet"</em>?<br/>One fix set of content, built with <strong>React.js</strong>, styled to perfection with&nbsp;
            <CountUp end={1200}>
              {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                  </VisibilitySensor>
              )}
              </CountUp>
              + lines of <strong>Sass</strong> (and still tbc).</p>
            <div className="flex justify-center w-full gap-10 mt-4">
              <div className="items-center flex flex-col">
                <h5 className="mb-1">Tech-Stack</h5>
                <div className="flex gap-4 items-center">
                  <Figma className='w-8 h-8 dynamic-svg' alt="Figma icon"/>
                  <ReactImg className='w-8 h-8 dynamic-svg' alt="React icon"/>
                  <SASS className='w-8 h-8 dynamic-svg' alt="Sass icon"/>
                </div>
              </div>
              <div>
                <h5 className="text-center">Link</h5>
                <div className="flex gap-4 mt-1 items-center">
                  <a href="https://history-of-webdesign-and-me.vercel.app/" target="_blank" rel="noreferrer noopener">
                    <button className='freestyletag'>Demo</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Bouncy Burgers section */}
        <div className='max-w-[1000px] w-full px-6 sm:px-1 grid sm:grid-cols-2 gap-1 md:gap-6 mb-[40px] sm:mb-[64px] justify-center items-center'>
          <VisibilitySensor partialVisibility={true} minTopValue={100}>
            {({ isVisible }) => (
              <div className="freedevice sm:order-2">
                <div className={isVisible ? "two-images zoom-in-left-big" : "two-images"} id={isVisible ?"bouncy-left" : ""}></div>
                <div className={isVisible ? "two-images zoom-in-right-small" : "two-images"} id={isVisible ?"bouncy-right" : ""}></div>
              </div>
            )}
          </VisibilitySensor>
          <div className='textinfo'>
            <h4>Bouncy Burgers</h4>
            <p className="pb-4">Landing page for a fictitious burger restaurant. In this project I explored <strong>react-spring</strong> parallax-effects, <strong>AOS</strong>-behaviours and how to pass props.<br/>Further, I used the <strong>Maplibre</strong>-API, wrapped up with <strong>react-map-gl</strong>, for a fully-functional map.</p>
            <div className="flex justify-center w-full gap-10 mt-4">
              <div className="items-center flex flex-col">
                <h5 className="mb-1">Tech-Stack</h5>
                <div className="flex gap-4 items-center">
                  <Figma className='w-8 h-8 dynamic-svg' alt="Figma icon"/>
                  <ReactImg className='w-8 h-8 dynamic-svg' alt="React icon"/>
                  <SASS className='w-8 h-8 dynamic-svg' alt="SASS icon"/>
                </div>
              </div>
              <div>
                <h5 className="text-center">Link</h5>
                <div className="flex gap-4 mt-1 items-center">
                  <a href="https://react-parallax-burger.vercel.app//" target="_blank" rel="noreferrer noopener">
                    <button className='freestyletag'>Demo</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Anita Pizza section */}
        <div className='max-w-[1000px] w-full px-6 sm:px-1 grid sm:grid-cols-2 gap-1 md:gap-6 mb-[40px] sm:mb-[64px] justify-center items-center'>
          <VisibilitySensor partialVisibility={true} minTopValue={100}>
            {({ isVisible }) => (
              <div className="freedevice">
                <div className={isVisible ? "two-images zoom-in-left-small" : "two-images"} id={isVisible ?"anita-left" : ""}></div>
                <div className={isVisible ? "two-images zoom-in-right-big" : "two-images"}  id={isVisible ?"anita-right" : ""}></div>
              </div>
            )}
          </VisibilitySensor>
          <div className='textinfo'>
            <h4>Anita Pizza</h4>
            <p className="pb-4">Craving Pizza? Anita got you covered! Landing page, built with <strong>React.js</strong> and <strong>Tailwind.css</strong>, with a huge amount of unique CSS-animations.<br/>Assemble your own Pizza or filter through the menu, based on your personal preferences.</p>
            <div className="flex justify-center w-full gap-10 mt-4">
              <div className="items-center flex flex-col">
                <h5 className="mb-1">Tech-Stack</h5>
                <div className="flex gap-4 items-center">
                  <Figma className='w-8 h-8 dynamic-svg' alt="Figma icon"/>
                  <ReactImg className='w-8 h-8 dynamic-svg' alt="React icon"/>
                  <Tailwind className='w-8 h-8 dynamic-svg' alt="React icon"/>
                </div>
              </div>
              <div>
                <h5 className="text-center">Link</h5>
                <div className="flex gap-4 mt-1 items-center">
                  <a href="https://anita-pizza.vercel.app/" target="_blank" rel="noreferrer noopener">
                    <button className='freestyletag'>Demo</button>
                  </a>
                </div>
              </div>
            </div>
        </div>
        </div>

        {/* Little leaf section */}
        <div className='max-w-[1000px] w-full px-6 sm:px-1 grid sm:grid-cols-2 gap-1 md:gap-6 mb-[40px] sm:mb-[64px] justify-center items-center'>
          <VisibilitySensor partialVisibility={true} minTopValue={100}>
            {({ isVisible }) => (
              <div className="freedevice sm:order-2">
                <div className={isVisible ? "two-images zoom-in-left-big" : "two-images"} id={isVisible ?"lile-left" : ""}></div>
                <div className={isVisible ? "two-images zoom-in-right-small" : "two-images"}  id={isVisible ?"lile-right" : ""}></div>
              </div>
            )}
          </VisibilitySensor>
          <div className='textinfo'>
            <h4>Little leaf</h4>
            <p className="pb-4">A fictitious webshop, for pretty little plants, built with <strong>React.js</strong> & <strong>Tailwind.css</strong>.<br/>Backend-functionalities, like adding or removing plants from your cart, are built with <strong>Firebase</strong> & <strong>Firestore</strong>.</p>
            <div className="flex justify-center w-full gap-10 mt-4">
            <div className="items-center flex flex-col">
              <h5 className="mb-1">Tech-Stack</h5>
                <div className="flex gap-4 items-center">
                  <Firebase className='w-8 h-8 dynamic-svg' alt="Figma icon"/>
                  <ReactImg className='w-8 h-8 dynamic-svg' alt="Sass icon"/>
                  <Tailwind className='w-8 h-8 dynamic-svg' alt="React icon"/>
                </div>
              </div>
              <div>
                <h5 className="text-center">Links</h5>
                <div className="flex gap-4 mt-1 items-center">
                  <a href="https://plant-shop-react.vercel.app/" target="_blank" rel="noreferrer noopener">
                    <button className='freestyletag'>Demo</button>
                  </a>
                  <a href="https://github.com/if-stella/plant-shop-react" target="_blank" rel="noreferrer noopener">
                    <button className='freestyletag'>Code</button>
                  </a>
                </div>
              </div>
            </div>
        </div>
        </div>

        {/* TXTflix section */}
        <div className='max-w-[1000px] w-full px-6 sm:px-1 grid sm:grid-cols-2 gap-1 md:gap-6 mb-[40px] sm:mb-[64px] justify-center items-center'>
          <VisibilitySensor partialVisibility={true} >
            {({ isVisible }) => (
              <div className="freedevice">
                <div className={isVisible ? "two-images zoom-in-left-small" : "two-images"} id={isVisible ?"txtflix-left" : ""}></div>
                <div className={isVisible ? "two-images zoom-in-right-big" : "two-images"}  id={isVisible ?"txtflix-right" : ""}></div>
              </div>
            )}
          </VisibilitySensor>
          <div className='textinfo'>
            <h4>TXTflix</h4>
            <p className="pb-4">A NETFLIX-copycat, built with <strong>React.js</strong>, refined with <strong>Tailwind.css</strong>, using the <strong>Firebase API</strong> and <strong>IMDB API</strong>. <br/>Besides the core functionalities, users can toggle the styles, to see everything in a glitchy teletext-look.</p>
            <div className="flex justify-center w-full gap-10 mt-4">
              <div className="items-center flex flex-col">
                <h5 className="mb-1">Tech-Stack</h5>
                <div className="flex gap-4 items-center">
                  <Firebase className='w-8 h-8 dynamic-svg' alt="Figma icon"/>
                  <ReactImg className='w-8 h-8 dynamic-svg' alt="Sass icon"/>
                  <Tailwind className='w-8 h-8 dynamic-svg' alt="React icon"/>
                </div>
              </div>
              <div>
                <h5 className="text-center">Links</h5>
                <div className="flex gap-4 mt-1 items-center">
                  <a href="https://retro-netflix-react-app.vercel.app/" target="_blank" rel="noreferrer noopener">
                    <button className='freestyletag'>Demo</button>
                  </a>
                  <a href="https://github.com/if-stella/react-retro-netflix" target="_blank" rel="noreferrer noopener">
                    <button className='freestyletag'>Code</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* GymAlly section */}
        <div className='max-w-[1000px] w-full px-6 sm:px-1 grid sm:grid-cols-2 gap-1 md:gap-6 mb-[40px] sm:mb-[64px] justify-center items-center'>
          <VisibilitySensor partialVisibility={true} minTopValue={100}>
            {({ isVisible }) => (
            <div className="freedevice sm:order-2">
              <div className={isVisible ? "two-images zoom-in-left-small" : "two-images"} id={isVisible ?"gymally-left" : ""}></div>
              <div className={isVisible ? "two-images zoom-in-right-big" : "two-images"}  id={isVisible ?"gymally-right" : ""}></div>
            </div>
            )}
          </VisibilitySensor>
          <div className='textinfo'>
            <h4>GymAlly</h4>
            <p className="pb-4">Feeling lost in the gym, between grunting gorillas? <strong>GymAlly</strong> is here to the rescue! Find like-minded people close to you, or in other gyms nearby, chat and send out calendar invites.<br/><strong>Rails</strong>-MVP-App, built as a Teamproject at the <strong><a href="https://www.lewagon.com/" target="_blank" rel="noreferrer noopener" className="textlink">Le Wagon</a></strong> Coding Bootcamp. Covered the Design & Frontend.</p>
            <div className="flex justify-center w-full gap-10 mt-4">
              <div className="items-center flex flex-col">
                <h5 className="mb-1">Tech-Stack</h5>
                <div className="flex gap-4 items-center">
                  <CSS className='w-8 h-8 dynamic-svg' alt="CSS icon"/>
                  <Figma className='w-8 h-8 dynamic-svg' alt="Figma icon"/>
                  <Rails className='w-8 h-8 dynamic-svg' alt="Rails icon"/>
                </div>
              </div>
              <div>
                <h5 className="text-center">Link</h5>
                <div className="flex gap-4 mt-1 items-center">
                  <a href="https://github.com/if-stella/GymAlly" target="_blank" rel="noreferrer noopener">
                    <button className='freestyletag'>Code</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Freestyle
