import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import EyesFollowMouse from './EyesFollowMouse';

AOS.init();

const About = () => {
  return (
    <div name='about' className='w-full sm:h-screen'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='max-w-[1000px] w-full px-4 grid grid-cols-2 gap-8'>
          <div className='text-left sm:text-right pb-8' data-aos="slide-up" data-aos-duration="300" data-aos-easing="ease-in-out">
            <h2 className='inline'>About</h2>
          </div>
        </div>
        <div className='max-w-[1000px] w-full px-4 grid sm:grid-cols-2 gap-4'>
        <EyesFollowMouse />
          <div className='text-left sm:px-8 sm-px-0'>
            <h3 className="mb-2" data-aos="slide-up" data-aos-duration="300" data-aos-easing="ease-in-out">Hey, I'm Stella.</h3>
            <p data-aos="slide-up" data-aos-duration="400" data-aos-easing="ease-in-out">
            <span className="mb-4 block">A multidisciplinary <strong>Ux/Ui-Designer</strong> / <strong>Visual Designer</strong>, based in Berlin, and currently working at <strong><a href="https://kanzleidrive.de/" target="_blank" rel="noreferrer noopener" className="textlink">Kanzleidrive</a></strong>, as a Senior UX/UI Designer.</span>
            <span className="mb-4 block">After finishing my graphicdesign education (in 2014) and my bachelor in communication design (<strong><a href="https://kd.htw-berlin.de/" target="_blank" rel="noreferrer noopener" className="textlink">HTW Berlin</a></strong>, 2018), I worked in different fields within the design sector (Screendesign, Productdesign, Visual Design, Art Direction).</span>
            2022 I joined an intense fullstack web development bootcamp, at <strong><a href="https://www.lewagon.com/berlin" target="_blank" rel="noreferrer noopener" className="textlink">Le Wagon</a></strong>, and for a while I worked there as a teacher myself, sharing my passion for great design and messy code.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
