import Navbar from "./components/Navbar";
import { Home } from "./components/Home";
import About from "./components/About";
import Skills from "./components/Skills";
import Freestyle from "./components/Freestyle";
import Contact from "./components/Contact";
import React, { useEffect, useState } from "react";
import {MdDarkMode} from 'react-icons/md';
import {BsFillSunFill} from 'react-icons/bs';
import './index.css'
import Footer from "./components/Footer";

function App() {

  const [theme, setTheme] = useState("light");

   useEffect(() => {
    const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const systemTheme = prefersDark ? "dark" : "light";
    setTheme(systemTheme);
    document.documentElement.setAttribute("data-theme", systemTheme);
  }, []);

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    document.documentElement.setAttribute("data-theme", newTheme);
  };

  return (
    <div className="app" data-theme={theme}>
      <Navbar />
      <Home/>
      <About/>
      <Skills/>
      <Freestyle/>
      <Contact/>
      <Footer/>
      <div onClick={switchTheme} className="theme-toggle">
        {theme === 'light' ? <MdDarkMode/> : <BsFillSunFill/>}
      </div>
    </div>
  );
}

export default App;
